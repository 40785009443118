import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { SidebarLockedState } from 'reducers/sidebar';
import LockIcon from '../../../assets/icon/sidebar/lock-icon.svg';
import UnlockIcon from '../../../assets/icon/sidebar/unlock-icon.svg';
import { RootState } from '../../../store/rootReducers';
import SubMenuItem from './components/SubMenuItem';
import { MainSidebarOptions, SUB_MENU_LIST } from './constants';
import { SubMenuDetail, SubMenuList } from './constants/SubMenuList.constants';
import { Container, Footer, Header, LockButton, MenuContainer } from './styles/SubSidebar.styles';

function SecondarySidebar(props: OwnProps) {
	const isIRFSATOrgEnabled = useSelector((state) => state.featureAccess.features.IRF_SAT);
	const { isCollapsed, lockedState, onChangeLockedState, loggedInAs } = props;

	const { pathname } = useLocation();
	const { t } = useTranslation();
	const [pathMenu, setPathMenu] = useState<SubMenuList>();
	const isSandboxAccount = useSelector((state: RootState) => state.jwtauth.isSandboxAccount);
	const profile = useSelector((state: RootState) => state.firebase.profile);
	const featureAccess = useSelector((state: RootState) => state.featureAccess.features);

	// Is users is from internal organization
	const loggedInUserOrgID = profile.organization!;
	const internalOrgList = ['nimbly', 'cs', 'sustainnovation', 'qa-featuredemo', 'qa-featuredemo-test'];
	const isInternalOrg = internalOrgList.includes(loggedInUserOrgID);
	const salesDemoOrg = [
		'fb-indonesia-1',
		'fb-indonesia-2',
		'fb-indonesia-3',
		'fb-indonesia-4',
		'fb-indonesia-5',
		'retail-indonesia-1',
		'retail-indonesia-2',
		'retail-indonesia-3',
		'retail-indonesia-4',
		'retail-indonesia-5',
		'fb-thai-1',
		'fb-thai-2',
		'fb-thai-3',
		'fb-thai-4',
		'fb-thai-5',
		'retail-thai-1',
		'retail-thai-2',
		'retail-thai-3',
		'retail-thai-4',
		'retail-thai-5',
		'fb-english-1',
		'fb-english-2',
		'fb-english-3',
		'fb-english-4',
		'fb-english-5',
		'retail-thai-1',
		'retail-thai-2',
		'retail-thai-3',
		'retail-thai-4',
		'retail-thai-5',
	];
	const isSalesDemoOrg = salesDemoOrg.includes(loggedInUserOrgID);

	const mainPath = pathname.split('/')[1] as MainSidebarOptions;

	const handleGetSubMenu = async () => {
		const pathMenu = await SUB_MENU_LIST(isSandboxAccount, featureAccess);

		setPathMenu(pathMenu);
	};
	const handleClickLock = () => {
		if (lockedState === 'locked') onChangeLockedState('unlocked');
		else onChangeLockedState('locked');
	};

	const handleRenderSubMenuList = () => {
		if (!pathMenu || !pathMenu?.[mainPath]) return;
		const isNimbly = profile.organization === 'sustainnovation' || profile.organization === 'nimbly';
		let path = mainPath === 'superadmin' ? 'admin' : mainPath;

		return pathMenu?.[path].map((menuDetail: SubMenuDetail, i: number) => {
			if (!menuDetail?.id) return null;

			const { id } = menuDetail;

			const conditions: any = {
				menu_api_integration: () => isNimbly,
				menu_irf_sat: () => isIRFSATOrgEnabled,
				menu_customer_feedback: () => isInternalOrg,
				menu_dsbrd_rank: () => isInternalOrg || isSalesDemoOrg,
				menu_fnb_insights: () => isInternalOrg || isSalesDemoOrg,
				menu_dsbrd_store_performance: () => isInternalOrg || isSalesDemoOrg,
				menu_dsbrd_nps: () => profile.role === 'superadmin',
				menu_superadmin: () => profile.role === 'superadmin',
				menu_dsbrd_health: () => featureAccess.HEALTH_DASHBOARD,
				menu_dsbrd_admin: () => profile.role === 'superadmin' || profile.role === 'account_holder',
			};
			if (conditions[id] && !conditions[id]()) return null;

			return (
				<SubMenuItem
					key={`submenu-${mainPath}-${i}`}
					{...menuDetail}
					onChangeSubMenuCollapsed={props.onChangeSubMenuCollapsed}
					subMenuCollapsedStateList={props.subMenuCollapsedStateList}
					id={id}
				/>
			);
		});
	};

	useEffect(() => {
		handleGetSubMenu();
	}, []);

	return (
		<Container isCollapsed={isCollapsed} isLocked={lockedState === 'locked'}>
			<Header>
				<span>{t('label.sidebar.loggedInAs')}</span>
				<span className="display-name">{loggedInAs}</span>
			</Header>

			<MenuContainer>{handleRenderSubMenuList()}</MenuContainer>

			<Footer>
				<LockButton onClick={handleClickLock}>
					<img src={lockedState === 'locked' ? LockIcon : UnlockIcon} alt="dashboard-lock-icon" />
				</LockButton>
			</Footer>
		</Container>
	);
}

interface OwnProps {
	isCollapsed: boolean;
	lockedState: SidebarLockedState;
	onChangeLockedState: (state: SidebarLockedState) => void;
	loggedInAs: string;
	onChangeSubMenuCollapsed: (subMenuID: string, isCollapsed: boolean) => void;
	subMenuCollapsedStateList: { [subMenuID: string]: boolean };
}

export default SecondarySidebar;
