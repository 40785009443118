import { ActionType, createReducer } from 'typesafe-actions';

import * as actions from './questionnaire.action';
import { QuestionnairesState } from './type';
import * as types from './questionnaire.actionTypes';

export type QuestionnaireAction = ActionType<typeof actions>;

const initialState: QuestionnairesState = {
  filterQuery: '',
  sortBy: 'title',
  title: 'asc',
  questions: 'asc',
  createdAt: 'asc',
  dateCreated: 'asc',
  dateUpdated: 'asc',
  modifiedBy: 'asc',
  status: 'asc',
  scrollPosition: 0,
  selectedQuestionnaire: null,
  modalShown: null,
  modalBulkShown: false,
  modalHistoryShown: false,
  modalLabelFlagShown: false,
  modalLabelFlagIndex: 'question-0',
  modalLabelFlagScope: 'questionnaire',
  modalLabelQuestionType: 'binary',
  modalLabelFlagCategoryIndex: 'category-0',
  modalLabelFlagConditionalIndex: null,
  ordered: [],
  data: {},
  index: {},
  isReady: false,
  isLoading: false,
  departments: [],
  questionnaires: null,
  questionnairesTemplate: null,
  downloadQuestionnairesTemplateURL: null,
  isLoadingTemplate: false,
  isLoadingDownload: false,
  error: null,
  tab: 'published',

  paginateQuestionnaires: [],
  page: 1,
  totalItem: 0,
  isLoadingTable: false
};

const questionnaireReducer = createReducer<QuestionnairesState, QuestionnaireAction>(initialState)
  .handleAction(actions.fetchQuestionnaires.request, state => ({
    ...state,
    error: null,
    isLoading: true
  }))
  .handleAction(actions.fetchQuestionnaires.success, (state, action) => ({
    ...state,
    questionnaires: action.payload.data,
    isLoading: false
  }))
  .handleAction(actions.fetchQuestionnaires.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoading: false
  }))
  .handleAction(actions.fetchPaginateQuestionnairesAsync.request, state => ({
    ...state,
    error: null,
    isLoadingTable: true
  }))
  .handleAction(actions.fetchPaginateQuestionnairesAsync.success, (state, action) => ({
    ...state,
    paginateQuestionnaires: action.payload.ordered,
    totalItem: action.payload.totalItem,
    isLoadingTable: false
  }))
  .handleAction(actions.fetchPaginateQuestionnairesAsync.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoadingTable: false
  }))
  .handleAction(types.SET_PAGE as any, (state, action) => ({
    ...state,
    page: action.payload.index
  }))
  .handleAction(types.SET_TAB as any, (state, action) => ({
    ...state,
    tab: action.payload.value
  }))
  .handleAction(types.SET_FILTER_QUERY as any, (state, action) => ({
    ...state,
    filterQuery: action.payload.text
  }))
  .handleAction(types.CLEAR_FILTER_QUERY as any, state => ({
    ...state,
    filterQuery: ''
  }))
  .handleAction(types.SET_SORT as any, (state, action) => {
    if (state.sortBy !== action.payload.newType) {
      return {
        ...state,
        sortBy: action.payload.newType,
        title: 'asc',
        questions: 'asc',
        createdAt: 'asc',
        dateCreated: 'asc',
        dateUpdated: 'asc',
        modifiedBy: 'asc',
        status: 'asc'
      };
    }
    return {
      ...state,
      [action.payload.newType]: action.payload.newOrder
    };
  })
  .handleAction(types.SET_SCROLL as any, (state, action) => ({
    ...state,
    scrollPosition: action.payload.newScroll
  }))
  .handleAction(types.SHOW_BULK_MODAL as any, state => ({
    ...state,
    modalBulkShown: true
  }))
  .handleAction(types.DISMISS_BULK_MODAL as any, state => ({
    ...state,
    modalBulkShown: false
  }))
  .handleAction(types.SHOW_HISTORY_MODAL as any, state => {
    return {
      ...state,
      modalHistoryShown: true
    };
  })
  .handleAction(types.DISMISS_HISTORY_MODAL as any, state => ({
    ...state,
    modalHistoryShown: false
  }))
  .handleAction(types.SHOW_MODAL as any, (state, action) => ({
    ...state,
    modalShown: action.payload.mode,
    selectedQuestionnaire: action.payload.questionnaire
  }))
  .handleAction(types.DISMISS_MODAL as any, state => ({
    ...state,
    modalShown: null
  }))
  .handleAction(types.SHOW_LABEL_FLAG_MODAL as any, (state, action) => ({
    ...state,
    modalLabelFlagShown: true,
    modalLabelFlagIndex: action.payload.questionId,
    modalLabelFlagScope: action.payload.scope,
    modalLabelQuestionType: action.payload.questionType,
    modalLabelFlagCategoryIndex: action.payload.categoryId,
    modalLabelFlagConditionalIndex: action.payload.conditionalQuestionId
  }))
  .handleAction(types.DISMISS_LABEL_FLAG_MODAL as any, state => ({
    ...state,
    modalLabelFlagShown: false
  }))
  .handleAction(types.UPDATE_QUESTIONNAIRE_DEPARTMENT_REQUEST as any, state => ({
    ...state,
    isLoading: true
  }))
  .handleAction(types.UPDATE_QUESTIONNAIRE_DEPARTMENT_SUCCESS as any, state => ({
    ...state,
    isLoading: false
  }))
  .handleAction(types.UPDATE_QUESTIONNAIRE_DEPARTMENT_FAILURE as any, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload.error
  }))
  .handleAction(types.CLONE_QUESTIONNAIRE_REQUEST as any, state => ({
    ...state,
    isLoading: true
  }))
  .handleAction(types.CLONE_QUESTIONNAIRE_SUCCESS as any, state => ({
    ...state,
    isLoading: false,
    modalShown: null,
    error: ''
  }))
  .handleAction(types.CLONE_QUESTIONNAIRE_FAILURE as any, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload.message
  }))
  .handleAction(types.FETCH_QUESTIONNAIRE_DEPARTMENTS_REQUEST as any, state => {
    return {
      ...state,
      isLoading: true
    };
  })
  .handleAction(types.FETCH_QUESTIONNAIRE_DEPARTMENTS_SUCCESS as any, (state, action) => ({
    ...state,
    departments: action.payload.data,
    isLoading: false
  }))
  .handleAction(types.FETCH_QUESTIONNAIRE_DEPARTMENTS_FAILURE as any, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload.error
  }))
  .handleAction(types.GET_POPULATED_QUESTIONNAIRES_REQUEST as any, state => ({
    ...state,
    isLoading: true
  }))
  .handleAction(types.GET_POPULATED_QUESTIONNAIRES_SUCCESS as any, (state, action) => {
    return {
      ...state,
      data: action.payload.data,
      ordered: action.payload.ordered,
      index: action.payload.index,
      isReady: true,
      isLoading: false
    };
  })
  .handleAction(types.GET_POPULATED_QUESTIONNAIRES_FAILURE as any, (state, action) => ({
    ...state,
    isReady: true,
    isLoading: false,
    error: action.payload.error
  }))
  .handleAction(types.REMOVE_PAGINATION_QUESTIONNAIRE_BY_ID as any, (state, action) => {
    return {
      ...state,
      paginateQuestionnaires: state.paginateQuestionnaires
        ? state.paginateQuestionnaires!.filter(q => {
            if (q.key !== action.payload.questionnaireID) {
              return true;
            }
            return false;
          })
        : null
    };
  })
  .handleAction(types.FETCH_QUESTIONNAIRES_TEMPLATE_REQUEST as any, state => ({
    ...state,
    isLoadingTemplate: true
  }))
  .handleAction(types.FETCH_QUESTIONNAIRES_TEMPLATE_SUCCESS as any, (state, action) => {
    return {
      ...state,
      questionnairesTemplate: action.payload.data,
      isLoadingTemplate: false
    };
  })
  .handleAction(types.FETCH_QUESTIONNAIRES_TEMPLATE_FAILURE as any, (state, action) => ({
    ...state,
    isLoadingTemplate: false,
    error: action.payload.error
  }))
  .handleAction(types.DOWNLOAD_QUESTIONNAIRES_TEMPLATE_REQUEST as any, state => ({
    ...state,
    isLoadingDownload: true
  }))
  .handleAction(types.DOWNLOAD_QUESTIONNAIRES_TEMPLATE_SUCCESS as any, (state, action) => {
    return {
      ...state,
      downloadQuestionnairesTemplateURL: action.payload.data,
      isLoadingDownload: false
    };
  })
  .handleAction(types.DOWNLOAD_QUESTIONNAIRES_TEMPLATE_FAILURE as any, (state, action) => ({
    ...state,
    isLoadingDownload: false,
    error: action.payload.error
  }));

export { questionnaireReducer, initialState };
