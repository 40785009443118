import BroadcastIcon_ACTIVE from '../../../../assets/icon/sidebar/admin-broadcast-icon_active.svg';
import BroadcastIcon_INACTIVE from '../../../../assets/icon/sidebar/admin-broadcast-icon_inactive.svg';
// ADMIN
import ManageIcon_ACTIVE from '../../../../assets/icon/sidebar/admin-manage-icon_active.svg';
import ManageIcon_INACTIVE from '../../../../assets/icon/sidebar/admin-manage-icon_inactive.svg';
import PromotionsIcon_ACTIVE from '../../../../assets/icon/sidebar/admin-promotions-icon_active.svg';
import PromotionsIcon_INACTIVE from '../../../../assets/icon/sidebar/admin-promotions-icon_inactive.svg';
import SalesTargetIcon_ACTIVE from '../../../../assets/icon/sidebar/admin-sales-target-icon_active.svg';
import SalesTargetIcon_INACTIVE from '../../../../assets/icon/sidebar/admin-sales-target-icon_inactive.svg';
import InventoryIcon_ACTIVE from '../../../../assets/icon/sidebar/dashboard-inventory-icon_active.svg';
import InventoryIcon_INACTIVE from '../../../../assets/icon/sidebar/dashboard-inventory-icon_inactive.svg';
import IssuesIcon_ACTIVE from '../../../../assets/icon/sidebar/dashboard-issues-icon_active.svg';
import IssuesIcon_INACTIVE from '../../../../assets/icon/sidebar/dashboard-issues-icon_inactive.svg';
// ANALYTICS
import OverviewIcon_ACTIVE from '../../../../assets/icon/sidebar/dashboard-overview-icon_active.svg';
import OverviewIcon_INACTIVE from '../../../../assets/icon/sidebar/dashboard-overview-icon_inactive.svg';
import SalesIcon_ACTIVE from '../../../../assets/icon/sidebar/dashboard-sales-icon_active.svg';
import SalesIcon_INACTIVE from '../../../../assets/icon/sidebar/dashboard-sales-icon_inactive.svg';
import SitesIcon_ACTIVE from '../../../../assets/icon/sidebar/dashboard-sites-icon_active.svg';
import SitesIcon_INACTIVE from '../../../../assets/icon/sidebar/dashboard-sites-icon_inactive.svg';
import TrendsIcon_ACTIVE from '../../../../assets/icon/sidebar/dashboard-trends-icon_active.svg';
import TrendsIcon_INACTIVE from '../../../../assets/icon/sidebar/dashboard-trends-icon_inactive.svg';
import UsersIcon_ACTIVE from '../../../../assets/icon/sidebar/dashboard-users-icon_active.svg';
import UsersIcon_INACTIVE from '../../../../assets/icon/sidebar/dashboard-users-icon_inactive.svg';
import AccessControlIcon_ACTIVE from '../../../../assets/icon/sidebar/settings-access-control-icon_active.svg';
import AccessControlIcon_INACTIVE from '../../../../assets/icon/sidebar/settings-access-control-icon_inactive.svg';
import FederatedIcon_ACTIVE from '../../../../assets/icon/sidebar/settings-federated-icon-active.svg';
import FederatedIcon_INACTIVE from '../../../../assets/icon/sidebar/settings-federated-icon-inactive.svg';
import StorePerformance_INACTIVE from '../../../../assets/icon/sidebar/store-performance-icon_inactive.svg';
import StorePerformance_ACTIVE from '../../../../assets/icon/sidebar/store-performance-icon_active.svg';
import FNBACTIVE from '../../../../assets/icon/sidebar/fnb_active.svg';
import FNBINACTIVE from '../../../../assets/icon/sidebar/fnb_inactive.svg';
import Executive_ACTIVE from '../../../../assets/icon/sidebar/executive-dashboard-icon-active.svg';
import Executive_INACTIVE from '../../../../assets/icon/sidebar/executive-dashboard-icon-inactive.svg';
// SETTINGS
import GeneralIcon_ACTIVE from '../../../../assets/icon/sidebar/settings-general-icon_active.svg';
import GeneralIcon_INACTIVE from '../../../../assets/icon/sidebar/settings-general-icon_inactive.svg';
import NotificationIcon_ACTIVE from '../../../../assets/icon/sidebar/settings-notification-icon_active.svg';
import NotificationIcon_INACTIVE from '../../../../assets/icon/sidebar/settings-notification-icon_inactive.svg';

import IntegrationIcon_ACTIVE from '../../../../assets/icon/sidebar/settings-integration-icon_active.svg';
import IntegrationIcon_INACTIVE from '../../../../assets/icon/sidebar/settings-integration-icon_inactive.svg';
import RoleManagerIcon_ACTIVE from '../../../../assets/icon/sidebar/settings-role-manager-icon_active.svg';
import RoleManagerIcon_INACTIVE from '../../../../assets/icon/sidebar/settings-role-manager-icon_inactive.svg';
// SUPERADMIN
import ActivityLogIcon_ACTIVE from '../../../../assets/icon/sidebar/superadmin__activity-log--active.svg';
import ActivityLogIcon_INACTIVE from '../../../../assets/icon/sidebar/superadmin__activity-log--inactive.svg';
import { MainSidebarOptions } from './general';

import DeleteUserActive from 'assets/icon/delete-users-active.svg';
import DeleteUserInActive from 'assets/icon/delete-users.svg';
import ChangeUserRoleActive from 'assets/icon/change-users-role-active.svg';
import ChangeUserRoleInActive from 'assets/icon/change-users-role.svg';
import HealthDashboardActive from 'assets/icon/health_dashboard_selected.svg';
import HealthDashboardInActive from 'assets/icon/health_dashboard_unselected.svg';
import RankingInActive from 'assets/icon/ranking_unselect.svg';
import RankingActive from 'assets/icon/ranking_select.svg';

import AnomaliesActive from 'assets/icon/anomalies_utility_selected.svg';
import AnomaliesInActive from 'assets/icon/anomalies_utility_unselected.svg';

import MetricsDownloadActive from 'assets/icon/metrics_download_selected.svg';
import MetricsDownloadInActive from 'assets/icon/metrics_download_unselected.svg';
import { FeaturesType } from '@nimbly-technologies/nimbly-common/lib/enumerators';

export interface SubMenuDetail {
	path: string;
	icon: {
		active: string;
		inactive: string;
	};
	label: string;
	collapsible?: boolean;
	collapsibleList?: { path: string; subPage?: string; label: string; id?: string }[];
	id?: string;
}

export type SubMenuList = Record<MainSidebarOptions, SubMenuDetail[]>;

export const SUB_MENU_LIST = (isSandboxAccount?: boolean, featureAccess?: { [K in FeaturesType]: boolean }) => {
	const SUB_MENU_DEFAULT: any = {
		analytics: [
			{
				path: '/analytics/executive',
				icon: {
					active: OverviewIcon_ACTIVE,
					inactive: OverviewIcon_INACTIVE,
				},
				label: 'nav.overview',
				id: 'menu_dsbrd_overview',
			},
			{
				path: '/analytics/admin-dashboard',
				icon: {
					active: RoleManagerIcon_ACTIVE,
					inactive: RoleManagerIcon_INACTIVE,
				},
				label: 'nav.adminDashboard',
				id: 'menu_dsbrd_admin',
			},
			{
				path: '/analytics/operational',
				icon: {
					active: Executive_ACTIVE,
					inactive: Executive_INACTIVE,
				},
				label: 'nav.operationalDashboard',
				id: 'menu_dsbrd_operational',
			},
			{
				path: '/analytics/trends',
				icon: {
					active: TrendsIcon_ACTIVE,
					inactive: TrendsIcon_INACTIVE,
				},
				label: 'nav.trends',
				id: 'menu_dsbrd_trends',
			},
			// {
			// 	path: '/analytics/sites',
			// 	icon: {
			// 		active: SitesIcon_ACTIVE,
			// 		inactive: SitesIcon_INACTIVE,
			// 	},
			// 	label: 'nav.sites',
			// 	id: 'menu_dsbrd_sites',
			// },
			// {
			// 	path: '/analytics/issues',
			// 	icon: {
			// 		active: IssuesIcon_ACTIVE,
			// 		inactive: IssuesIcon_INACTIVE,
			// 	},
			// 	label: 'nav.issues',
			// 	id: 'menu_dsbrd_issues',
			// },
			// {
			// 	path: '/analytics/auditors',
			// 	icon: {
			// 		active: UsersIcon_ACTIVE,
			// 		inactive: UsersIcon_INACTIVE,
			// 	},
			// 	label: 'nav.users',
			// 	id: 'menu_dsbrd_users',
			// },
			{
				path: '/analytics/inventory',
				icon: {
					active: InventoryIcon_ACTIVE,
					inactive: InventoryIcon_INACTIVE,
				},
				label: 'nav.inventory',
				id: 'menu_dsbrd_inventory',
			},
			// {
			// 	path: '/analytics/stocks',
			// 	icon: {
			// 		active: LowStockIcon_ACTIVE,
			// 		inactive: LowStockIcon_INACTIVE,
			// 	},
			// 	label: 'nav.siteWithLowStocks',
			// 	id: 'menu_dsbrd_low_stocks',
			// },
			{
				path: '/analytics/calculation',
				icon: {
					active: SalesIcon_ACTIVE,
					inactive: SalesIcon_INACTIVE,
				},
				label: 'nav.salesCalculation',
				id: 'menu_dsbrd_sales_calc',
			},
			{
				path: '/analytics/irfsat',
				icon: {
					active: SalesIcon_ACTIVE,
					inactive: SalesIcon_INACTIVE,
				},
				label: 'nav.irfsat',
				id: 'menu_irf_sat',
			},
			// {
			// 	path: '/analytics/ranking',
			// 	icon: {
			// 		active: RankingActive,
			// 		inactive: RankingInActive,
			// 	},
			// 	label: 'nav.ranking',
			// 	id: 'menu_dsbrd_rank',
			// },
			// {
			// 	path: '/analytics/fnb-insights',
			// 	icon: {
			// 		active: FNBACTIVE,
			// 		inactive: FNBINACTIVE,
			// 	},
			// 	label: 'nav.fnb_insights',
			// 	id: 'menu_fnb_insights',
			// },
			// {
			// 	path: '/analytics/store-performance',
			// 	icon: {
			// 		active: StorePerformance_ACTIVE,
			// 		inactive: StorePerformance_INACTIVE,
			// 	},
			// 	label: 'nav.storeperformance',
			// 	id: 'menu_dsbrd_store_performance',
			// },
			// {
			// 	path: '/analytics/ceo-persona',
			// 	icon: {
			// 		active: Executive_ACTIVE,
			// 		inactive: Executive_INACTIVE,
			// 	},
			// 	label: 'nav.ceopersona',
			// 	id: 'menu_dsbrd_ceo_persona',
			// },
			{
				path: '/analytics/nps',
				icon: {
					active: SalesIcon_ACTIVE,
					inactive: SalesIcon_INACTIVE,
				},
				label: 'nav.nps',
				id: 'menu_dsbrd_nps',
			},
			{
				path: '/analytics/health',
				icon: {
					active: HealthDashboardActive,
					inactive: HealthDashboardInActive,
				},
				label: 'label.health.health',
				id: 'menu_dsbrd_health',
			},
			// {
			// 	path: '/analytics/metrics',
			// 	icon: {
			// 		active: SalesIcon_ACTIVE,
			// 		inactive: SalesIcon_INACTIVE,
			// 	},
			// 	label: 'nav.metrics',
			// 	id: 'menu_dsbrd_metrics',
			// },
			//		{
			//			path: '/analytics/competitor-analysis',
			//			icon: {
			//				active: SalesIcon_ACTIVE,
			//				inactive: SalesIcon_INACTIVE,
			//			},
			//			label: 'nav.competitorAnalysis',
			//		},
		],
		issues: [],
		admin: [
			{
				path: '/superadmin/organizations',
				id: 'menu_superadmin',
				icon: {
					active: AccessControlIcon_ACTIVE,
					inactive: AccessControlIcon_INACTIVE,
				},
				label: 'nav.superadmin',
				collapsible: true,
				collapsibleList: [
					{
						path: '/superadmin/organizations',
						icon: {
							active: AccessControlIcon_ACTIVE,
							inactive: AccessControlIcon_INACTIVE,
						},
						label: 'nav.organization',
						id: 'menu_organization',
					},
					{
						path: '/superadmin/npsSurvey',
						icon: {
							active: AccessControlIcon_ACTIVE,
							inactive: AccessControlIcon_INACTIVE,
						},
						label: 'nav.npsSurvey',
						id: 'menu_nps_survey',
					},
					{
						path: '/superadmin/errorlog',
						icon: {
							active: AccessControlIcon_ACTIVE,
							inactive: AccessControlIcon_INACTIVE,
						},
						label: 'nav.errorLog',
						id: 'menu_error_log',
					},
					{
						path: '/superadmin/organization-insight',
						icon: {
							active: AccessControlIcon_ACTIVE,
							inactive: AccessControlIcon_INACTIVE,
						},
						label: 'Insight',
						id: 'menu_insight',
					},
					{
						path: '/superadmin/clientLogin',
						icon: {
							active: AccessControlIcon_ACTIVE,
							inactive: AccessControlIcon_INACTIVE,
						},
						label: 'Client Login',
						id: 'menu_client_login',
					},
					{
						path: '/superadmin/activity-log',
						icon: {
							active: ActivityLogIcon_ACTIVE,
							inactive: ActivityLogIcon_INACTIVE,
						},
						label: 'User Activity',
						id: 'menu_user_activity',
					},
					{
						path: '/superadmin/reqForDemo',
						icon: {
							active: AccessControlIcon_ACTIVE,
							inactive: AccessControlIcon_INACTIVE,
						},
						label: 'Request For Demo',
					},
				],
			},
			{
				path: '/admin/sites',
				id: 'menu_adm_manage',
				icon: {
					active: ManageIcon_ACTIVE,
					inactive: ManageIcon_INACTIVE,
				},
				label: 'nav.admin',
				collapsible: true,
				collapsibleList: [
					{
						path: '/admin/departments',
						subPage: '/admin/departmentsgroup',
						label: 'nav.departments',
						id: 'menu_dept',
					},
					{
						path: '/admin/questionnaires',
						label: 'nav.questionnaires',
						id: 'menu_questionnaires',
					},
					{
						path: '/admin/auditors',
						label: 'nav.auditors',
						id: 'menu_auditors',
					},
					{
						path: '/admin/sites',
						label: 'nav.sites',
						id: 'menu_sites',
					},
					...(featureAccess?.BULK_OPERATIONS
						? [
								{
									path: '/admin/bulk-operations',
									label: 'nav.bulkOperations',
									id: 'menu_sites',
								},
						  ]
						: []),
					{
						path: '/admin/skuManagement',
						label: 'nav.skuManagement',
						id: 'menu_skuManagement',
					},
					{
						path: '/admin/stockAuditScheduler',
						label: 'nav.stockAuditScheduler',
						id: 'menu_stockAuditScheduler',
					},
					{
						path: '/admin/offdays',
						label: 'nav.offDays',
						id: 'menu_non_opr_days',
					},
					{
						path: '/admin/customerFeedback',
						label: 'nav.customerFeedback',
						id: 'menu_customer_feedback',
					},
					// {
					// 	path: '/admin/downloadManager',
					// 	label: 'nav.downloadManager',
					// 	id: 'menu_download_mng1',
					// },
					{
						path: '/admin/journeyPlan',
						label: 'nav.journeyPlan',
						id: 'menu_jurney_plan',
					},
					{
						path: '/admin/data-explorer',
						label: 'nav.dataExplorer',
						id: 'menu_data_explorer',
					},
				],
			},
			{
				path: '/admin/salestarget',
				icon: {
					active: SalesTargetIcon_ACTIVE,
					inactive: SalesTargetIcon_INACTIVE,
				},
				label: 'nav.salesTarget',
				id: 'menu_adm_sales_target',
			},
			{
				path: '/admin/promotions',
				icon: {
					active: PromotionsIcon_ACTIVE,
					inactive: PromotionsIcon_INACTIVE,
				},
				label: 'nav.promotions',
				id: 'menu_adm_promo',
			},
			{
				path: '/admin/broadcast',
				icon: {
					active: BroadcastIcon_ACTIVE,
					inactive: BroadcastIcon_INACTIVE,
				},
				label: 'nav.broadcast',
				id: 'menu_adm_brdcast',
			},
		],
		help: [],
		settings: [
			{
				path: '/settings/general',
				icon: {
					active: GeneralIcon_ACTIVE,
					inactive: GeneralIcon_INACTIVE,
				},
				label: 'nav.general',
				id: 'menu_general',
			},
			{
				path: '/settings/notification',
				icon: {
					active: NotificationIcon_ACTIVE,
					inactive: NotificationIcon_INACTIVE,
				},
				label: 'nav.notification',
				id: 'menu_notification',
			},
			{
				path: '/settings/role-manager',
				icon: {
					active: RoleManagerIcon_ACTIVE,
					inactive: RoleManagerIcon_INACTIVE,
				},
				label: 'nav.roleManager',
				id: 'menu_role_mng',
			},
			{
				path: '/settings/permissions',
				icon: {
					active: AccessControlIcon_ACTIVE,
					inactive: AccessControlIcon_INACTIVE,
				},
				label: 'nav.accessControl',
				id: 'menu_access_control',
			},
			{
				path: '/settings/feature-access',
				icon: {
					active: AccessControlIcon_ACTIVE,
					inactive: AccessControlIcon_INACTIVE,
				},
				label: 'nav.featureAccess',
				id: 'menu_feature_access',
			},
			{
				path: '/settings/integration',
				icon: {
					active: IntegrationIcon_ACTIVE,
					inactive: IntegrationIcon_INACTIVE,
				},
				label: 'nav.apiIntegration',
				id: 'menu_api_integration',
			},
			{
				path: '/settings/federated-account',
				icon: {
					active: FederatedIcon_ACTIVE,
					inactive: FederatedIcon_INACTIVE,
				},
				label: 'nav.federatedAccount',
				id: 'menu_settings_federated_account',
			},
		],
		customerSuccess: [
			{
				path: '/customerSuccess/changeUserEmail',
				icon: {
					active: RoleManagerIcon_ACTIVE,
					inactive: RoleManagerIcon_INACTIVE,
				},
				label: 'Change User Email',
				id: 'menu_changeUserEmail',
			},
			{
				path: '/customerSuccess/users-role-change',
				icon: {
					active: ChangeUserRoleActive,
					inactive: ChangeUserRoleInActive,
				},
				label: 'Change Users Role',
				id: 'menu_usersRoleChange',
			},
			{
				path: '/customerSuccess/delete-users',
				icon: {
					active: DeleteUserActive,
					inactive: DeleteUserInActive,
				},
				label: 'Delete Users',
				id: 'menu_deleteUsers',
			},
			{
				path: '/customerSuccess/deleteReports',
				icon: {
					active: GeneralIcon_ACTIVE,
					inactive: GeneralIcon_INACTIVE,
				},
				label: 'Delete Missed Reports',
				id: 'menu_deleteReports',
			},
			{
				path: '/customerSuccess/anomalies',
				icon: {
					active: AnomaliesActive,
					inactive: AnomaliesInActive,
				},
				label: 'Anomalies Utility',
				id: 'menu_anomaliesUtility',
			},
			{
				path: '/customerSuccess/metrics-download',
				icon: {
					active: MetricsDownloadActive,
					inactive: MetricsDownloadInActive,
				},
				label: 'Metrics Download',
				id: 'menu_metricsDownload',
			},
		],
		superadmin: [
			{
				path: '/superadmin/organizations',
				id: 'menu_superadmin',
				icon: {
					active: ManageIcon_ACTIVE,
					inactive: ManageIcon_INACTIVE,
				},
				label: 'nav.superadmin',
				collapsible: true,
				collapsibleList: [
					{
						path: '/superadmin/organizations',
						icon: {
							active: AccessControlIcon_ACTIVE,
							inactive: AccessControlIcon_INACTIVE,
						},
						label: 'nav.organization',
						id: 'menu_organization',
					},
					{
						path: '/superadmin/npsSurvey',
						icon: {
							active: AccessControlIcon_ACTIVE,
							inactive: AccessControlIcon_INACTIVE,
						},
						label: 'nav.npsSurvey',
						id: 'menu_nps_survey',
					},
					{
						path: '/superadmin/errorlog',
						icon: {
							active: AccessControlIcon_ACTIVE,
							inactive: AccessControlIcon_INACTIVE,
						},
						label: 'nav.errorLog',
						id: 'menu_error_log',
					},
					{
						path: '/superadmin/organization-insight',
						icon: {
							active: AccessControlIcon_ACTIVE,
							inactive: AccessControlIcon_INACTIVE,
						},
						label: 'Insight',
						id: 'menu_insight',
					},
					{
						path: '/superadmin/clientLogin',
						icon: {
							active: AccessControlIcon_ACTIVE,
							inactive: AccessControlIcon_INACTIVE,
						},
						label: 'Client Login',
						id: 'menu_client_login',
					},
					{
						path: '/superadmin/activity-log',
						icon: {
							active: ActivityLogIcon_ACTIVE,
							inactive: ActivityLogIcon_INACTIVE,
						},
						label: 'User Activity',
						id: 'menu_user_activity',
					},
					{
						path: '/superadmin/reqForDemo',
						icon: {
							active: AccessControlIcon_ACTIVE,
							inactive: AccessControlIcon_INACTIVE,
						},
						label: 'Request For Demo',
					},
				],
			},
		],
	};

	const disabledSandBoxFeatureId = [
		'menu_dsbrd_inventory',
		'menu_dsbrd_sales_calc',
		'menu_adm_sales_target',
		'menu_inventory',
		'menu_purchase_order',
		'menu_customer_feedback',
		'menu_jurney_plan',
		'menu_api_integration',
	];

	if (!isSandboxAccount) {
		return SUB_MENU_DEFAULT;
	}

	let constructMenus: any = {};
	for (const key in SUB_MENU_DEFAULT) {
		if (SUB_MENU_DEFAULT[key]) {
			constructMenus[key] = SUB_MENU_DEFAULT[key].filter((v: any) => {
				if (disabledSandBoxFeatureId.includes(v.id)) {
					return '';
				}
				return v;
			});

			constructMenus[key].forEach((obj: SubMenuDetail, index: number) => {
				if (obj?.collapsible && obj?.collapsibleList) {
					constructMenus[key][index].collapsibleList = obj.collapsibleList.filter((v: any) => {
						if (disabledSandBoxFeatureId.includes(v.id)) {
							return '';
						}
						return v;
					});
				}
			});
		}
	}

	return constructMenus;
};
