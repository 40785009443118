import { apiURL } from 'config/baseURL';
import API from 'helpers/api';
import { checkExpiredStorageItem, setToSession } from 'helpers/sessionStorageHelper';
import { PopulatedQuestionnaireIndex, Questionnaire, QuestionnaireIndex } from 'nimbly-common';
import { getToken } from 'reducers/api';
// utils
import * as actions from 'reducers/questionnaire/questionnaire.action';
import * as types from 'reducers/questionnaire/questionnaire.actionTypes';
import {
	ExtendQuestionnaire,
	QuestionnaireByPropsState,
	QuestionnaireData,
	QuestionnaireOrdered,
	QuestionnairesState,
} from 'reducers/questionnaire/type';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import fetchQuestionnaireByProps from 'services/fetchQuestionnaireByProps';
// types
import { RootState } from 'store/rootReducers';

interface Action {
	type: string;
	payload: any;
	meta: string | undefined;
}
const QUESTIONNAIRES = 'QUESTIONNAIRES';

export function* fetchPaginateQuestionnaires(action: Action) {
	const getState = (state: RootState) => state;
	const state: RootState = yield select(getState);

	const qState: QuestionnairesState = state.questionnaire;
	const sortField = action.payload;
	const withDisabled: boolean = qState.tab === 'deleted';

	try {
		yield put(actions.setLoading(true));
		const token = yield call(API.getFirebaseToken);

		const query: { [key: string]: string | number | boolean } = {
			with_disabled: withDisabled,
			search: qState.filterQuery || '',
			page: qState.page,
			limit: 16,
		};

		let sortFieldQuery = '';

		if (sortField) {
			sortFieldQuery = Object.keys(sortField)
				.map((field) => {
					return `${field}=${sortField[field]}`;
				})
				.join('&');
		}

		let queryStr = Object.keys(query).length
			? Object.keys(query)
					.map((key: string) => {
						return `${key}=${query[key]}`;
					})
					.join('&')
			: '';

		queryStr = queryStr ? queryStr + '&' + sortFieldQuery : sortFieldQuery;

		const url = `${apiURL}/questionnaires/questionnaireIndexes/paginate?${queryStr}`;
		const response = yield call(API.get, url, token);

		if (response && response.status === 200) {
			const result = yield response.json();
			const paginateQuestionnaire: QuestionnaireOrdered = [];

			result.data.docs.forEach((qIndex: any) => {
				const questionnaire = qIndex.populated.latest;
				if (Object.keys(questionnaire).length) {
					questionnaire.nameModifiedBy = Object.keys(qIndex.populated.modifiedBy).length
						? qIndex.populated.modifiedBy.displayName || ''
						: '';

					questionnaire.createdAt = qIndex.createdAt ?? '';

					paginateQuestionnaire.push({
						key: qIndex.questionnaireIndexID,
						value: questionnaire,
					});
				}
			});

			yield put(
				actions.fetchPaginateQuestionnairesAsync.success({
					ordered: paginateQuestionnaire,
					totalItem: result.data.totalDocs,
				}),
			);
			return;
		}

		const result = yield response.json();
		yield put(actions.fetchPaginateQuestionnairesAsync.failure({ error: result.message }));
	} catch (err) {
		yield put(actions.fetchPaginateQuestionnairesAsync.success({ ordered: [], totalItem: 0 }));
		yield put(actions.fetchPaginateQuestionnairesAsync.failure({ error: '' }));
	}
}

export function* fetchQuestionnaires() {
	try {
		yield put(actions.setLoading(true));
		const token = yield getToken();

		const options = {
			method: 'GET',
			headers: {
				authorization: token,
			},
		};
		const questionnaire = checkExpiredStorageItem<any>(QUESTIONNAIRES);
		if (!questionnaire) {
			const url = `${apiURL}/questionnaires`;
			const request = () => fetch(url, options);
			const response = yield call(request);
			if (response && response.status === 200) {
				const result = yield response.json();

				setToSession(result, QUESTIONNAIRES);
				const mappedQuestionnaire: { [id: string]: Questionnaire } = {};

				result.data.forEach((questionnaire: Questionnaire, i: number) => {
					const { questionnaireID } = questionnaire;
					if (!mappedQuestionnaire.hasOwnProperty(questionnaireID)) {
						mappedQuestionnaire[questionnaireID] = questionnaire;
					}
				});
				yield put(actions.fetchQuestionnaires.success({ data: mappedQuestionnaire }));
				return null;
			}

			const result = yield response.json();
			yield put(actions.fetchQuestionnaires.failure({ error: result.message }));
			return null;
		} else {
			const result = questionnaire;
			const mappedQuestionnaire: { [id: string]: Questionnaire } = {};

			result.data.forEach((questionnaire: Questionnaire, i: number) => {
				const { questionnaireID } = questionnaire;
				if (!mappedQuestionnaire.hasOwnProperty(questionnaireID)) {
					mappedQuestionnaire[questionnaireID] = questionnaire;
				}
			});
			yield put(actions.fetchQuestionnaires.success({ data: mappedQuestionnaire }));
			return null;
		}
	} catch (err) {
		yield put(actions.fetchQuestionnaires.success({ data: {} }));
		yield put(actions.fetchQuestionnaires.failure({ error: '' }));
		return null;
	}
}

function* getPopulatedQuestionnaires() {
	const questionnaireIndex: { [key: string]: QuestionnaireIndex } = {};
	const ordered: QuestionnaireOrdered = [];
	const data: QuestionnaireData = {};
	try {
		const authToken = yield getToken();

		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
			},
		};

		const getQuestionairesIndexURL = `${apiURL}/questionnaires/questionnaireIndexes`;
		const request = () => fetch(getQuestionairesIndexURL, options);
		const response = yield call(request);

		if (response && response.status === 200) {
			const respData = yield response.json();

			respData.data.forEach((q: PopulatedQuestionnaireIndex) => {
				if (Object.keys(q.populated.latest).length && !q.disabled) {
					const qIndexKey: string = q.questionnaireIndexID;
					if (!questionnaireIndex.hasOwnProperty(qIndexKey)) {
						// FIXME: KOMANG, what it the types
						const questionnaire: ExtendQuestionnaire = q.populated.latest as any;

						const populated: any = q.populated;

						questionnaire.nameModifiedBy = populated.modifiedBy ? populated.modifiedBy.displayName || '' : '';
						ordered.push({ key: qIndexKey, value: questionnaire });
						data[qIndexKey] = questionnaire;
						questionnaireIndex[qIndexKey] = q;
					}
				}
			});
			return yield put(actions.getPopulatedQuestionnairesAsync.success({ data, ordered, index: questionnaireIndex }));
		} else {
			yield put(actions.getPopulatedQuestionnairesAsync.success({ data, ordered, index: {} }));
		}
	} catch (e) {
		yield put(actions.getPopulatedQuestionnairesAsync.success({ data, ordered, index: {} }));
		yield put(actions.getPopulatedQuestionnairesAsync.failure({ error: e.message }));
	}
}

function* getQuestionnairesByProps() {
	try {
		const questionnaireProps: Array<keyof Omit<Questionnaire, 'questions'>> = [
			'questionnaireID',
			'organizationID',
			'title',
			'status',
			'dateCreated',
			'dateUpdated',
			'disabled',
			'type',
			'questionnaireIndexID',
			'modifiedBy',
			'tags',
			'autoAssignment',
		];
		const data: QuestionnaireByPropsState['data'] = yield fetchQuestionnaireByProps(questionnaireProps) as unknown;
		yield put(actions.getQuestionnairesByPropsAsync.success({ data }));
	} catch (error) {
		yield put(actions.getQuestionnairesByPropsAsync.failure({ error: error.message }));
	}
}

function* updateQuestionnaireDepartments(action: Action) {
	try {
		const authToken = yield getToken();
		const questionnaireKey = action.payload.questionnaireKey;
		const departments = action.payload.data;
		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify({ questionnaireIndex: questionnaireKey, departmentIDs: departments }),
		};

		const updateQuestionnaireDepartmentsURL = `${apiURL}/departments/questionnaire-index`;
		const request = () => fetch(updateQuestionnaireDepartmentsURL, options);
		const response = yield call(request);

		if (response && response?.status === 200) {
			yield put(actions.updateQuestionnaireDepartment.success());
		} else {
			const responseData = yield response.json();
			yield put(actions.updateQuestionnaireDepartment.failure({ error: responseData.message }));
			return null;
		}
	} catch (e) {
		yield put(actions.updateQuestionnaireDepartment.failure({ error: 'Failed to Update Questionnaire Departments' }));
		return null;
	}
}

function* fetchQuestionnaireDepartments(action: Action) {
	try {
		const authToken = yield getToken();
		const questionnaireKey = action.payload.questionnaireKey;

		if (authToken === '' || questionnaireKey === '') {
			yield put(actions.fetchQuestionnaireDepartment.failure({ error: 'Invalid request' }));
			return;
		}

		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
			},
		};

		const fetchQuestionnaireURL = `${apiURL}/departments/questionnaire/${questionnaireKey}`;
		const request = () => fetch(fetchQuestionnaireURL, options);
		const response = yield call(request);

		if (response && response.status === 200) {
			const responseData = yield response.json();
			yield put(actions.fetchQuestionnaireDepartment.success({ data: responseData.data }));
		} else {
			const responseData = yield response.json();
			yield put(actions.fetchQuestionnaireDepartment.failure({ error: responseData.message }));
		}
	} catch (e) {
		yield put(actions.fetchQuestionnaireDepartment.failure({ error: 'Failed to Fetch Questionnaire Departments' }));
	}
}

function* fetchQuestionnaireTemplate() {
	try {
		const authToken = yield getToken();
		if (authToken === '') {
			yield put(actions.fetchQuestionnairesTemplate.failure({ error: 'Invalid request' }));
			return;
		}

		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
			},
		};

		const fetchQuestionnairesTemplateURL = `${apiURL}/questionnaires/questionnairetemplates`;
		const request = () => fetch(fetchQuestionnairesTemplateURL, options);
		const response = yield call(request);

		if (response && response.status === 200) {
			const responseData = yield response.json();
			yield put(actions.fetchQuestionnairesTemplate.success({ data: responseData.data }));
		} else {
			const responseData = yield response.json();
			yield put(actions.fetchQuestionnairesTemplate.failure({ error: responseData.message }));
		}
	} catch (e) {
		yield put(actions.fetchQuestionnairesTemplate.failure({ error: 'Failed to Fetch Questionnaire Template' }));
	}
}

function* downloadQuestionnaireTemplate(action: Action) {
	try {
		const authToken = yield getToken();
		if (authToken === '') {
			yield put(actions.downloadQuestionnaireTemplate.failure({ error: 'Invalid request' }));
			return;
		}

		const options = {
			method: 'GET',
			headers: {
				Authorization: authToken,
			},
		};

		const downloadQuestionnairesTemplateURL = `${apiURL}/questionnaires/questionnairetemplates/template?title=${action.payload}`;
		const request = () => fetch(downloadQuestionnairesTemplateURL, options);
		const response = yield call(request);

		if (response && response.status === 200) {
			const responseData = yield response.json();
			yield put(actions.downloadQuestionnaireTemplate.success({ data: responseData.data }));
		} else {
			const responseData = yield response.json();
			yield put(actions.downloadQuestionnaireTemplate.failure({ error: responseData.message }));
		}
	} catch (e) {
		yield put(actions.downloadQuestionnaireTemplate.failure({ error: 'Failed to download Questionnaire Template' }));
	}
}

export default function* questionnaireSaga() {
	yield takeLatest(types.FETCH_QUESTIONNAIRE_DEPARTMENTS_REQUEST, fetchQuestionnaireDepartments);
	yield takeLatest(types.FETCH_QUESTIONNAIRES_REQUEST, fetchQuestionnaires);
	yield takeEvery(types.FETCH_PAGINATE_QUESTIONNAIRES_REQUEST, fetchPaginateQuestionnaires);
	yield takeLatest(types.UPDATE_QUESTIONNAIRE_DEPARTMENT_REQUEST, updateQuestionnaireDepartments);
	yield takeLatest(types.GET_POPULATED_QUESTIONNAIRES_REQUEST, getPopulatedQuestionnaires);
	yield takeLatest(types.FETCH_QUESTIONNAIRES_TEMPLATE_REQUEST, fetchQuestionnaireTemplate);
	yield takeLatest(types.DOWNLOAD_QUESTIONNAIRES_TEMPLATE_REQUEST, downloadQuestionnaireTemplate);
	yield takeLatest(types.FETCH_QUESTIONNAIRES_BY_PROPS_REQUEST, getQuestionnairesByProps);
}
